import React, { useState } from 'react';
import LayoutHeaderFooter from '../../components/LayoutHeaderFooter/LayoutHeaderFooter';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import FirstComponentSmall from '../../components/FirstComponentSmall/FirstComponentSmall';
import Button from '../../components/Button/Button';
import kep from '../../assets/images/PenztargepFeloldas/fejlec_kep.jpg';
import clock from '../../assets/images/PenztargepFeloldas/ora.svg';
import keressBizalommal from '../../assets/images/PenztargepFeloldas/keress_bizalommal.svg';
import elsoMasodik from '../../assets/images/PenztargepFeloldas/1-2pont.svg';
import harmadik from '../../assets/images/PenztargepFeloldas/3pont.svg';
import negyedikOtodik from '../../assets/images/PenztargepFeloldas/4-5pont.svg';
import webalk from '../../assets/images/PenztargepFeloldas/webes_alkalmazas.svg';
import atutalassal from '../../assets/images/PenztargepFeloldas/atutalas.svg';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ClearIcon from '@material-ui/icons/Clear';
import './penztargepFeloldas.scss';

const useStyles = makeStyles({
  table: {
    minWidth: 300,
    maxWidth: 600,
    margin: '30px auto',
    backgroundColor: '#FAFAFA'
  },
  head: {
    color: '#B0B0B0'
  }
});

const content = [
  {
    image: elsoMasodik,
    items: [
      {
        icon: '1',
        desc:
          '"P mód"-ban keressük meg a "Bérbeadás" menüpontot, és az "Összesen" gombbal lépjünk be!'
      },
      {
        icon: '2',
        desc: 'Kiírja a gép, hogy "Jelszó beállítása", és a blokkolás dátumát'
      }
    ]
  },
  {
    image: harmadik,
    items: [
      {
        icon: '3',
        desc:
          'A lekérdezett kódok közül válasszuk ki az eszköz által kiírt dátumhoz tartozó kódot!'
      }
    ]
  },
  {
    image: negyedikOtodik,
    items: [
      {
        icon: '4',
        desc:
          'Üsd be a számbillentyűk segítségével a kódot, és nyomd meg az "Összesen" gombot'
      },
      {
        icon: '5',
        desc:
          'Amennyiben egy sípoló hangot hallasz, és kilép a bérbeadás menüből, a művelet sikeres volt. Újra belépve a menübe már a következő blokkolási dátumot kell látnod.'
      }
    ]
  }
];

const PenztargepBerles = () => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [rows, setRows] = useState([]);

  function createData(date, unblockCode) {
    return { date, unblockCode };
  }

  const handleChange = () => {
    const regex = /^AP[A-Z]{1}[0-9]{8}$/;

    if (regex.test(`AP${inputValue}`) === false) {
      setInputError('* A megadott formátum nem megfelelő (pl.:A12345678)!');
      setShowTable(false);
    } else {
      setInputError('');
      handleRequest();
    }
  };

  const handleRequest = () => {
    fetch(
      `${process.env.GATSBY_CASH_REGISTER_RENTING_URL}?apNumber=${inputValue}`
    )
      .then((content) => content.json())
      .then((data) => {
        if (data.errorMessages && data.errorCode) {
          setInputError('* A megadott AP szám nem található');
          throw new Error('Dobtam egy errort');
        } else {
          let rowDatas = [];
          for (let index = 0; index < 15; index++) {
            rowDatas.push(
              createData(
                data.rentData[index].blockingDate,
                data.rentData[index].unblockCode
              )
            );
          }
          setRows(rowDatas);
          setShowTable(true);
        }
      })
      .catch((e) => e);
  };

  return (
    <div className="penztargepFeloldas-wrapper">
      <LayoutHeaderFooter
        header_background_color={'#363636'}
        SEO_title={'CMO - Pénztárgép feloldás'}
        SEO_description={'CMO - Pénztárgép feloldás'}
        SEO_keywords={'CMO, Pénztárgép feloldás'}
      >
        <FirstComponentSmall image={kep} title={ undefined } description={ undefined } />
        <LayoutComponent>
          <h1 className="title">Kedves pénztárgép bérlő!</h1>
          <div className="description">
            Ezen az oldalon tudod elérni pénztárgéped feltöltőkódjait,
            amennyiben a számlád kifizetésre került. A mező kitöltésével meg fog
            jelenni az előző 15 feloldó kód a megadott pénztárgépre.
          </div>
          <div className="description desc">
            Add meg pénztárgéped AP számát, melyet az eszközöd oldalán, vagy a
            napi záráskor kapott blokk végén találsz.
          </div>
          <div className="AP-form">
            <div className="input-wrapper">
              <div id="AP">AP</div>
              <input
                placeholder="A12345678"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => (e.key === 'Enter' ? handleChange() : null)}
              />
            </div>
            <p className="inputError">{inputError}</p>

            <Button
              variant={'contained'}
              background_color={'#5107FF'}
              color={'white'}
              text={'Lekérdezem'}
              onClick={() => {
                handleChange();
              }}
            ></Button>
          </div>
          <div className="description">
            A pénztárgép feloldásához szükséges a pénztárgép összes korábbi
            tartozását rendezni. Nézd meg, hogy pénztárgépednek mikor jár le az
            előfizetése, elkészítettük-e már a számlát, vagy egyenlíts azonnal a
            'Fizetés' gombra kattintva.
          </div>

          {showTable && (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head} align="center">
                    Blokkolási dátum
                  </TableCell>
                  <TableCell className={classes.head} align="center">
                    Feloldó kód
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={`row_${index}`}>
                    <TableCell component="th" scope="row" align="center">
                      {new Date(row.date).toLocaleDateString('hu-HU')}
                    </TableCell>
                    <TableCell align="center">
                      {row.unblockCode ? (
                        row.unblockCode
                      ) : (
                        <ClearIcon style={{ color: '#FF6376' }} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </LayoutComponent>
        <div className="tartozas">
          <LayoutComponent background_color="#F7F7FA">
            <h2 className="title">
              Amennyiben tartozásod van, két módon rendezheted:
            </h2>
            <section>
              <div className="section-item">
                <div className="image-wrapper">
                  <img src={webalk} alt="webes alkalmazassal" />
                </div>
                <div className="titl">Webes alkalmazáson keresztül</div>
                <div className="description">
                  Bankkártyával - A CMO fiókodba belépve a 'Licenszkezelés'
                  menüpont alatt. <br />
                  <strong>
                    Ennek előnye, hogy pár másodperc alatt a számla kifizetett
                    státuszó lesz, és a blokkolási kód már elérhetővé is válik
                    itt a felületen.
                  </strong>
                </div>
                <a
                  href="https://app.cmo.hu"
                  target={'_blank'}
                  rel={'noopener noreferrer nofollow'}
                >
                  <Button
                    variant={'contained'}
                    background_color={'#5107FF'}
                    color={'white'}
                    text={'Belépés az alkalmazásba'}
                  ></Button>
                </a>
              </div>
              <div className="section-item">
                <div className="image-wrapper">
                  <img src={atutalassal} alt="atutalassal" />
                </div>
                <div className="titl">Átutalással</div>
                <div className="description">
                  Átutalással - amelynek hátránya, hogy{' '}
                  <strong>1 - 3 munkanapot is igénybe vehet</strong>, míg a
                  befizetés átfut a rendszeren. Ha blokkolt a pénztárgép, nem
                  fogod tudni használni azt.
                  <p>Kedvezményezett neve: CMO24 Zrt.</p>
                  <p>Számlavezető bank: Takarékbank Zrt.</p>
                  <p>Számlaszám: 50460100-10013670-00000000</p>
                </div>
              </div>
            </section>
          </LayoutComponent>
          <div className="step-by-step">
            <LayoutComponent
              background={`radial-gradient(47.47% 47.47% at 45.43% 52.53%, rgb(173, 177, 255) 0%, rgb(134, 139, 252) 100%)`}
            >
              <h2 className="title">A feloldási kód megadásának menete</h2>
              <strong>
                Fontos, hogy a pénztárgép napi zárt állapotban legyen!
              </strong>
              <section>
                <div className="content">
                  {content.map((item, index) => {
                    return (
                      <div
                        className={index % 2 !== 0 ? 'item reversed' : 'item'}
                        key={index}
                      >
                        <div className="image-wrapper">
                          <img src={item.image} alt="item" />
                        </div>
                        <div className="rows">
                          {item.items.map((row, i) => {
                            return (
                              <div className={'row'} key={i}>
                                <div className="icon">{row.icon}</div>
                                <div className="desc">{row.desc}</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </section>
              <p id="last-step">
                Visszalépve a napnyitásra elkezdheted használni a pénztárgépet.
              </p>
              <h2 className="title">Videós segédletünket itt találod:</h2>
              <a
                href="https://www.youtube.com/watch?v=XmOshxbvic8&ab_channel=CMO24Hungary"
                target={'_blank'}
                rel={'noopener noreferrer nofollow'}
              >
                <Button
                  variant={'contained'}
                  background_color={'#5107FF'}
                  color={'white'}
                  text={'Videó megtekintése'}
                ></Button>
              </a>
            </LayoutComponent>
          </div>
        </div>
        <div className="keress_bizalommal">
          <LayoutComponent>
            <img
              src={keressBizalommal}
              alt="keress bizalommal"
              className="keressBizalommal_illu"
            />
            <h2 className="title">Keress bennünket bizalommal!</h2>
            <img src={clock} alt="opening_hours" className="clock" />
            <div className="opening_hours">09 - 16h</div>
            <div className="desc">
              Amennyiben hibát tapasztalsz, keress bennünket munkanapokon 9.00 -
              16.00 között elérhetőségeinken.
            </div>
          </LayoutComponent>
        </div>
      </LayoutHeaderFooter>
    </div>
  );
};

export default PenztargepBerles;
